import React from "react";

const PageCover = React.forwardRef((props, ref) => {
  return (
    <div className={`pagecover ${props.name}`} ref={ref} data-density="hard">
      <div className="content">
        <div className="gradient"></div>
        <img className="img" src={props.image} alt=""></img>
      </div>
    </div>
  );
});

export default PageCover;
