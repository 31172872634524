/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "@albelli/arc-3/lib/icon.css";
import Maximise from "./components/Maximise";
import Slideshow from "./components/Slideshow";
import Next from "./components/Next";
import Previous from "./components/Previous";
import Book from "./components/Book";
import PageNumber from "./components/PageNumber";
import Error from "./components/Error";
import { Bootstrap } from "./components/Bootstrap";
import MetaTags from "./components/MetaTags";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

const App = ({ config }) => {
  const viewerApi = config.ViewerApi;
  const handle = useFullScreenHandle();
  const [state, setState] = useState({
    page: 0,
    totalPages: 0,
    pages: [],
    flipBook: useRef(null),
    timer: null,
    height: 0,
    width: 0,
    err: false,
    rerender: false,
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const widgetId = urlParams.get("wid") || urlParams.get("widgetId");
    const securityId = urlParams.get("sid") || urlParams.get("securityId") || "";
    const pageNumber = urlParams.get("page");

    window.newrelic && window.newrelic.setPageViewName(widgetId, window.location.host);
    window.newrelic && window.newrelic.setCustomAttribute("WidgetId", widgetId);

    const fetchData = async () => {
      try {
        if (!widgetId) {
          setState({
            ...state,
            err: true,
          });
          return;
        }
        const result = await fetch(`${viewerApi}/${widgetId}/${securityId}`);
        const text = await result.text();
        let data = { ...JSON.parse(text), startPage: pageNumber };
        if (!data.pages) {
          return;
        }

        setState({
          ...state,
          pages: data.pages,
          totalPages: data.pages.length - 2,
          height: data.height,
          width: data.width,
          startPage: data.startPage ? parseInt(data.startPage) + 1 : 0,
        });
      } catch (error) {
        setState({
          ...state,
          err: true,
        });
      }
    };
    fetchData();
  }, []);
  const [fullscreen, setFullscreen] = useState(false);

  return (
    <Bootstrap>
      <MetaTags />
      {state.err === false && (
        <FullScreen
          handle={handle}
          onChange={(value) => {
            if (fullscreen !== value) setFullscreen(value);
          }}
        >
          <div id="menu">
            <Maximise
              fullscreen={fullscreen}
              setFullscreen={setFullscreen}
              enter={handle.enter}
              exit={handle.exit}
              state={state}
              setState={setState}
            ></Maximise>
            <Slideshow state={state} setState={setState} />
          </div>
          <div className="bookContainer">
            <div className="navigation">{state.page !== 0 && <Previous state={state} />}</div>
            {state.height !== 0 && <Book state={state} setState={setState} />}
            <div className="navigation">{state.page <= state.totalPages && <Next state={state} />}</div>
          </div>
          <PageNumber page={state.page} mode={state.mode} totalPages={state.totalPages} />
        </FullScreen>
      )}
      {state.err === true && <Error />}
    </Bootstrap>
  );
};

export default App;
