import Button from "./Button";
import { FormattedMessage } from "react-intl";

const fullScreenApiSupported = document.fullscreenEnabled;

function openFullsizeWindow() {
  var options = `width=${window.screen.availWidth},height=${window.screen.availWidth},menubar=no,status=no,fullscreen=yes,resizable=no`;
  var where = window.location.search + "&popup=true" + window.location.hash;
  window.open(where, "_blank", options);
}

const Maximise = ({ fullscreen, setFullscreen, enter, exit, state, setState }) => {
  let onClick = () => {
    fullscreen ? exit() : enter();
    //setFullscreen(!fullscreen);
    setTimeout(() => setState({ ...state, rerender: true }), 100);
  };

  if (!fullScreenApiSupported) {
    let startFullscreen = window.location.search.includes("popup=true");
    if (!fullscreen && startFullscreen) setFullscreen(true);
    onClick = () => {
      if (!startFullscreen) {
        openFullsizeWindow();
      } else {
        window.close();
      }
    };
  }

  return (
    <>
      <div className="screen" onClick={onClick}>
        {fullscreen && (
          <>
            <Button name="close" size="medium" />
            <div className="playtext hide-for-small">
              <FormattedMessage id="ExitFullscreen" defaultMessage="Exit Fullscreen" />
            </div>
          </>
        )}
        {!fullscreen && (
          <>
            <Button name="maximize" size="medium" />
            <div className="playtext hide-for-small">
              <FormattedMessage id="Fullscreen" defaultMessage="Fullscreen" />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Maximise;
