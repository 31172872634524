import { useIntl, defineMessages } from "react-intl";
import { Helmet } from "react-helmet-async";

const messages = defineMessages({
  title: {
    id: "TitleText",
    defaultMessage: "View Online Photobook",
  },
  desc: {
    id: "MetaDescription",
    defaultMessage: "A preview of your beautiful photo book",
  },
});
const MetaTags = () => {
  const intl = useIntl();
  return (
    <Helmet>
      <title>{intl.formatMessage(messages.title)}</title>
      <meta name="description" content={intl.formatMessage(messages.desc)} />
      <meta property="og:title" content={intl.formatMessage(messages.title)} />
    </Helmet>
  );
};

export default MetaTags;
