import React from "react";

const Page = React.forwardRef((props, ref) => {
  return (
    <div className={`page ${props.num}`} ref={ref}>
      <div className="content">
        <div className="gradient"></div>
        <img className="img" src={props.image} alt=""></img>
      </div>
    </div>
  );
});

export default Page;
