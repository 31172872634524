import React, { useEffect, useState } from "react";
import HTMLFlipBook from "react-pageflip";
import Page from "./Page";
import PageCover from "./PageCover";

const Flipbook = ({ state, minWidth, maxWidth, minHeight, maxHeight, setState }) => {
  const onPage = (e) => {
    setState({
      ...state,
      page: e.data,
      startPage: e.data,
    });

    const url = new URL(window.location.href);
    url.searchParams.delete("page");

    //portrait page 1 vs landscape page 0-1 = page 1.
    if (state.mode === "portrait") {
      if (e.data - 1 > 0) url.searchParams.set("page", e.data - 1 || 1);
    } else if (e.data > 0) url.searchParams.set("page", e.data - 1 || 1);

    window.history.replaceState(null, "", url);
  };
  const onInit = (e) => {
    if (state.startPage) state.flipBook.current?.pageFlip()?.turnToPage(state.startPage);
    setState({
      ...state,
      ...e.data,
      page: state.startPage,
    });
  };
  const onChangeOrientation = (e) => {
    setState({
      ...state,
      mode: e.data,
    });
  };
  const data = state.pages;
  const pages = [<PageCover key={0} image={data[0]} name="front"></PageCover>];
  let pageNum = 0;
  for (let i = 0; i < data.length - 2; i++) {
    pageNum++;
    pages.push(<Page key={i + 1} image={data[pageNum]} num={i}></Page>);
  }
  pages.push(<PageCover key={pageNum + 1} image={data[pageNum + 1]} name="back"></PageCover>);
  return (
    <HTMLFlipBook
      width={state.width / 4}
      height={state.height / 2}
      size="stretch"
      minWidth={minWidth}
      maxWidth={maxWidth}
      minHeight={minHeight}
      maxHeight={maxHeight}
      maxShadowOpacity={0.5}
      showCover={true}
      mobileScrollSupport={true}
      onFlip={onPage}
      onInit={onInit}
      onChangeOrientation={onChangeOrientation}
      ref={state.flipBook}
    >
      {pages}
    </HTMLFlipBook>
  );
};
const Book = ({ state, setState }) => {
  const [rerender, setRerender] = useState({ active: false });
  const [cover, setCover] = useState(false);

  const landscape = state.width / 2 > state.height;
  const portrait = state.width / 2 < state.height;
  const caclulateMinWidth = () => {
    //square and landscape are the same
    let minWidth = 200;
    if (window.innerHeight <= 300) {
      minWidth = 100;
    }
    if (portrait) {
      if (window.innerHeight > 300) {
        minWidth = 150;
      } else {
        minWidth = 100;
      }
    }
    return minWidth;
  };
  const calculationMaxWidth = () => {
    //square is default
    let maxWidth = window.innerHeight - 80;
    if (landscape) {
      maxWidth = window.innerHeight + window.innerHeight / 3 - 80;
    }
    if (portrait) {
      //updated and stored until rerender is triggered
      maxWidth = (window.innerHeight * 2) / 3;
    }
    return maxWidth;
  };

  const maxWidth = calculationMaxWidth();
  const maxHeight = window.innerHeight - 32;
  const minWidth = caclulateMinWidth();
  let minHeight = 100;

  //external trigger - allow trigger rerender from state.rerender change and reset after
  useEffect(() => {
    if (state.rerender) {
      setCover(true);
      setRerender({ active: true });
      setState({ ...state, rerender: false });
    }
  }, [setState, state, state.rerender]);

  //Debounce the set to "Completed"
  useEffect(() => {
    if (rerender.active) {
      setRerender({ active: false });
      setTimeout(() => setCover(false), 50);
    }
  }, [rerender.active]);

  //handle resize and trigger rerender with debounce
  useEffect(() => {
    let timer = null;
    function handleResize() {
      if (!rerender.active) {
        clearTimeout(timer);
        timer = setTimeout(() => {
          setCover(true);
          setRerender({ active: true });
        }, 300);
      }
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      clearTimeout(timer);
    };
  });

  return (
    <div className="book">
      {cover && <div className="cover"></div>}
      {!rerender.active && (
        <Flipbook
          state={state}
          minHeight={minHeight}
          maxHeight={maxHeight}
          minWidth={minWidth}
          maxWidth={maxWidth}
          setState={setState}
        ></Flipbook>
      )}
    </div>
  );
};

export default Book;
