import { IntlProvider } from "react-intl";
import React, { useEffect, useState } from "react";
import { HelmetProvider } from "react-helmet-async";
const helmetContext = {};

export const Bootstrap = ({ children }) => {
  const [state, setState] = useState({
    messages: null,
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const fetchData = async (local) => {
      local = local || "en-GB";
      try {
        const result = await fetch("/locale/" + local + ".json");
        const text = await result.text();
        setState({ messages: JSON.parse(text) });
      } catch (error) {
        console.error(error);
        if (local !== "en-GB") await fetchData("en-GB");
      }
    };
    fetchData(queryParams.get("locale"));
  }, []);

  if (!state.messages) {
    return null;
  }

  return (
    <HelmetProvider context={helmetContext}>
      <IntlProvider locale="en" messages={state.messages}>
        {children}
      </IntlProvider>
    </HelmetProvider>
  );
};
