const PageNumber = ({ page, totalPages, mode }) => {
  const pageFrom = page - 1;
  let pageTo = page;
  //when showing only 1 page
  if (mode === "portrait") pageTo = null;

  let left = null;
  let right = null;
  if ((pageFrom > 0 || pageTo > 0) && pageTo <= totalPages - 1 && pageFrom <= totalPages - 2) {
    //on page 0-1 we need to show only 1/N
    left = <span className="num_left">{pageTo}</span>;
    if (pageFrom >= 1) {
      //on 2-3 we show 2-3
      left = <span className="num_left">{pageFrom}</span>;
      if (pageTo && pageTo > 0 && pageTo <= totalPages - 2)
        left = (
          <>
            <span className="num_left">{pageFrom}</span>
            <span> - </span>
            <span className="num_right">{pageTo}</span>
          </>
        );
    }
    right = <span className="total"> / {totalPages - 2}</span>;
  }
  return (
    <div className="numberpages">
      {left}
      {right}
    </div>
  );
};

export default PageNumber;
