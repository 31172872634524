import { FormattedMessage } from "react-intl";
const Error = () => {
  return (
    <div className="error">
      <FormattedMessage
        id="WrongID"
        defaultMessage="The online version of your photo book is not ready yet.\nPlease try again later or contact our customer service if it's still not available after 24 hours."
      />
    </div>
  );
};

export default Error;
