/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import Button from "./Button";
import { FormattedMessage } from "react-intl";

const Slideshow = ({ state, setState }) => {
  const [playHidden, setPlayHidden] = useState(false);
  const [pauseHidden, setPauseHidden] = useState(true);
  const [loop, setLoop] = useState(false);
  const pageDelay = 7000;

  const slideShow = () => {
    setLoop(!loop);
  };

  useEffect(() => {
    const turnPage = () => {
      if (state.page < state.totalPages + 1) {
        state.flipBook.current.pageFlip().flipNext();
      } else if (state.page >= state.totalPages + 1) {
        state.flipBook.current.pageFlip().flip(0);
      }
    };

    if (loop) {
      setState({
        ...state,
        timer: setTimeout(() => {
          turnPage();
        }, pageDelay),
      });
    } else {
      clearTimeout(state.timer);
    }
    return () => {
      clearTimeout(state.timer);
    };
  }, [state.page, loop]);

  return (
    <div
      className="loop"
      onClick={() => {
        slideShow();
        setPauseHidden(!pauseHidden);
        setPlayHidden(!playHidden);
      }}
    >
      {!pauseHidden && (
        <>
          <Button name="pause_button" size="medium" />
          <div className="playtext hide-for-small">
            <FormattedMessage id="Pause" defaultMessage="Pause slideshow" />
          </div>
        </>
      )}
      {!playHidden && (
        <>
          <Button name="play_button" size="medium" />
          <div className="playtext hide-for-small">
            <FormattedMessage id="Play" defaultMessage="Play slideshow" />
          </div>
        </>
      )}
    </div>
  );
};

export default Slideshow;
