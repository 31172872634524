import Button from "./Button";

const Previous = ({ state }) => {
  const prevButtonClick = () => {
    clearTimeout(state.timer);
    state.flipBook.current.pageFlip().flipPrev();
  };
  return (
    <>
      <Button onClick={prevButtonClick} name="arrow_left navigate previous" size="medium" />
    </>
  );
};

export default Previous;
