import Button from "./Button";

const Next = ({ state }) => {
  const nextButtonClick = () => {
    clearTimeout(state.timer);
    state.flipBook.current.pageFlip().flipNext();
  };
  return (
    <>
      <Button onClick={nextButtonClick} name="arrow_right navigate next" size="medium" />
    </>
  );
};

export default Next;
