import { Icon } from "@albelli/arc-3-react/lib/Icon";

const Button = (props) => {
  return (
    <div
      onClick={props.onClick}
      className={props.name}
      style={{ visibility: props.sight }}
    >
      <Icon name={props.name} size={props.size}></Icon>
    </div>
  );
};

export default Button;
